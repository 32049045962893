var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
var searchResultItem = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n"])));
var searchResultHeader = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var searchResultTitle = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    margin-bottom: 16px;\n"])));
var searchResultDescription = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-bottom: 8px;\n    white-space: pre-line;\n\n    > p:last-child {\n        margin-bottom: 0;\n    }\n"])));
var searchResultFooter = css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
var searchResultBtnWithNoSpacing = css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    padding: 0;\n"])));

var searchResultEntityType = function searchResultEntityType(theme) {
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    padding: 0;\n    cursor: default;\n    pointer-events: none;\n    text-transform: capitalize;\n    color: ", ";\n"])), theme.colors.text);
};

var searchResultTitleLink = function searchResultTitleLink(theme) {
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    padding: 0;\n    color: ", ";\n    font-size: ", ";\n    font-weight: normal;\n\n    &:hover {\n        color: ", ";\n    }\n"])), theme.colors.text, theme.typography.heading.h4, theme.colors.linkExternal);
};

export { searchResultItem, searchResultHeader, searchResultTitle, searchResultDescription, searchResultFooter, searchResultBtnWithNoSpacing, searchResultEntityType, searchResultTitleLink };