import React from 'react';
import { withTheme } from '@grafana/ui';
import { getCardStyles } from './styles';
export var Card = function Card(props) {
  var _a;

  var background = (_a = props.background) !== null && _a !== void 0 ? _a : 'strong';
  var styles = getCardStyles(props.theme, background);
  return React.createElement("div", {
    className: styles.container
  }, props.children);
};
export default withTheme(Card);