export var ViewState;

(function (ViewState) {
  ViewState[ViewState["Detail"] = 0] = "Detail";
  ViewState[ViewState["Search"] = 1] = "Search";
  ViewState[ViewState["Index"] = 2] = "Index";
})(ViewState || (ViewState = {}));

export var initialView = function initialView() {
  return ViewState.Index;
};