var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
var asideContainer = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    grid-area: aside;\n"])));
var asideButton = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding-left: 0;\n    padding-right: 0;\n"])));

var asideButtonInactive = function asideButtonInactive(theme) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    color: ", ";\n    cursor: default;\n\n    &:hover {\n        color: ", ";\n    }\n"])), theme.colors.text, theme.colors.text);
};

export { asideContainer, asideButton, asideButtonInactive };