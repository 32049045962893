var _templateObject;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
import { stylesFactory } from '@grafana/ui';
export var getCardStyles = stylesFactory(function (theme, background) {
  return {
    container: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n            width: 100%;\n            padding: ", ";\n            border-radius: ", ";\n            background: ", ";\n        "])), theme.spacing.md, theme.border.radius.sm, background === 'strong' ? theme.colors.bg2 : theme.colors.bg1)
  };
});