import { ADD_BOOKMARK, CLEAR_BOOKMARKS, REMOVE_BOOKMARK } from './types';
export var addBookmark = function addBookmark(item) {
  return {
    type: ADD_BOOKMARK,
    payload: item
  };
};
export var removeBookmark = function removeBookmark(item) {
  return {
    type: REMOVE_BOOKMARK,
    payload: item
  };
};
export var clearBookmarks = function clearBookmarks() {
  return {
    type: CLEAR_BOOKMARKS
  };
};