import React from 'react';
import ServicesContext from '../../contexts/services';

var withServices = function withServices(Component) {
  return function WithServices(props) {
    // https://github.com/Microsoft/TypeScript/issues/28938
    // since TS 3.2 spread erases type
    return React.createElement(ServicesContext.Consumer, null, function (services) {
      return React.createElement(Component, Object.assign({}, props, {
        services: services
      }));
    });
  };
};

export default withServices;