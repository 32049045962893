var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
var searchPageContainer = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    grid-area: content;\n"])));

var searchPageMatchesDesc = function searchPageMatchesDesc(theme) {
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    display: inline-block;\n    color: ", ";\n"])), theme.colors.textSemiWeak);
};

var searchPageElapsed = function searchPageElapsed(theme) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    display: inline-block;\n    font-style: italic;\n    color: ", ";\n"])), theme.colors.textWeak);
};

var paginationContainer = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin: 0 auto;\n"])));
export { searchPageContainer, searchPageMatchesDesc, searchPageElapsed, paginationContainer };