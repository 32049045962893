var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css, cx } from 'emotion';
var searchContainer = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    display: flex;\n    grid-area: header;\n"])));
var searchSubmitBtn = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-left: 16px;\n"])));
var searchFormGroup = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    display: flex;\n    width: 100%;\n"])));
var searchBlockWrapper = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    width: 100%;\n"])));
var autosuggestWrapper = css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    position: relative;\n"])));

var autosuggestIcon = function autosuggestIcon(theme) {
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 50%;\n    left: ", ";\n    transform: translate(-50%, -50%);\n"])), theme.spacing.md);
};

var autosuggestContainer = function autosuggestContainer(theme) {
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n        position: relative;\n        height: ", "px;\n    "])), theme.spacing.formInputHeight);
};

var autosuggestContainerOpen = css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    /* container class when dropdown opens */\n"])));

var autosuggestInput = function autosuggestInput(theme) {
  return cx(css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n            background: transparent;\n            height: ", "px;\n            width: 100%;\n            padding: 0 ", " 0 28px;\n            border-style: solid;\n            border-width: 1px;\n            border-color: ", ";\n            border-radius: ", ";\n        "])), theme.spacing.formInputHeight, theme.spacing.md, theme.colors.formInputBorder, theme.border.radius.sm));
};

var autosuggestInputOpen = css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral([""])));
var autosuggestInputFocused = css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral([""])));

var autosuggestSuggestionsContainer = function autosuggestSuggestionsContainer(theme) {
  return css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    display: none;\n    position: absolute;\n    top: calc(100% + 6px);\n    left: 0%;\n    width: 100%;\n    background: ", ";\n    z-index: 10;\n    padding: 0;\n    border-style: solid;\n    border-width: 1px;\n    border-color: ", ";\n    border-radius: ", ";\n"])), theme.colors.bodyBg, theme.colors.formInputBorder, theme.border.radius.sm);
};

var autosuggestSuggestionsContainerOpen = css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    display: block !important;\n"])));
var autosuggestSuggestionsList = css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n"])));

var autosuggestSuggestion = function autosuggestSuggestion(theme) {
  return css(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n    background: transparent;\n    line-height: ", "px;\n    width: 100%;\n    padding: 0 ", ";\n    cursor: pointer;\n    & + & {\n        border-top: 1px solid ", ";\n    }\n"])), theme.spacing.formInputHeight, theme.spacing.md, theme.colors.formInputBorder);
};

var autosuggestSuggestionFirst = css(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral([""])));

var autosuggestSuggestionHighlighted = function autosuggestSuggestionHighlighted(theme) {
  return css(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    color: ", ";\n"])), theme.colors.textBlue);
};

var autosuggestSectionContainer = css(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral([""])));
var autosuggestSectionContainerFirst = css(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral([""])));
var autosuggestSectionTitle = css(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral([""])));
export { searchContainer, searchSubmitBtn, searchFormGroup, searchBlockWrapper, autosuggestWrapper, autosuggestIcon, autosuggestContainer, autosuggestContainerOpen, autosuggestInput, autosuggestInputOpen, autosuggestInputFocused, autosuggestSuggestionsContainer, autosuggestSuggestionsContainerOpen, autosuggestSuggestionsList, autosuggestSuggestion, autosuggestSuggestionFirst, autosuggestSuggestionHighlighted, autosuggestSectionContainer, autosuggestSectionContainerFirst, autosuggestSectionTitle };