import { initialQuery, initialState } from './state';
import { CLEAR_QUERY, SET_QUERY } from './types';

var queryReducer = function queryReducer(state, action) {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case SET_QUERY:
      return action.payload;

    case CLEAR_QUERY:
      return initialQuery();

    default:
      return state;
  }
};

export { queryReducer };