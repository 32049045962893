import { initialView } from './state';
import { SET_VIEW } from './types';

var viewReducer = function viewReducer(state, action) {
  if (state === undefined) {
    return initialView();
  }

  switch (action.type) {
    case SET_VIEW:
      return action.payload;

    default:
      return state;
  }
};

export { viewReducer };