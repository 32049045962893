export var LOAD_METRIC_INIT = 'LOAD_METRIC_INIT';
export var LOAD_METRIC_PENDING = 'LOAD_METRIC_PENDING';
export var LOAD_METRIC_SUCCESS = 'LOAD_METRIC_SUCCESS';
export var LOAD_METRIC_ERROR = 'LOAD_METRIC_ERROR';
export var LOAD_METRIC_SIBLINGS_INIT = 'LOAD_METRIC_SIBLINGS_INIT';
export var LOAD_METRIC_SIBLINGS_PENDING = 'LOAD_METRIC_SIBLINGS_PENDING';
export var LOAD_METRIC_SIBLINGS_SUCCESS = 'LOAD_METRIC_SIBLINGS_SUCCESS';
export var LOAD_METRIC_SIBLINGS_ERROR = 'LOAD_METRIC_SIBLINGS_ERROR';
export var LOAD_INDOM_INIT = 'LOAD_INDOM_INIT';
export var LOAD_INDOM_PENDING = 'LOAD_INDOM_PENDING';
export var LOAD_INDOM_SUCCESS = 'LOAD_INDOM_SUCCESS';
export var LOAD_INDOM_ERROR = 'LOAD_INDOM_ERROR';