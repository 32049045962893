var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
var detailPageContainer = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    grid-area: content;\n"])));
var detailPageItem = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n"])));
var detailPageHeader = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
var detailPageTitle = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    margin-bottom: 16px;\n"])));

var detailPageEntityType = function detailPageEntityType(theme) {
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    margin-bottom: 16px;\n    padding: 0;\n    cursor: default;\n    pointer-events: none;\n    text-transform: capitalize;\n    color: ", ";\n"])), theme.colors.text);
};

var detailPageDescription = css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    margin-bottom: 8px;\n    white-space: pre-line;\n\n    > p:last-child {\n        margin-bottom: 0;\n    }\n"])));
var detailPageActions = css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    margin-top: 16px;\n"])));
var detailPageProperties = css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    width: 100%;\n"])));
var detailPageBtn = css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    padding: 0;\n"])));
var gridList = css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    display: flex;\n    width: 100%;\n    flex-wrap: wrap;\n    justify-content: space-between;\n\n    > * {\n        flex: 1 1 calc(50% - 5px);\n        max-width: calc(50% - 5px);\n    }\n\n    > *:nth-child(2n + 3),\n    > *:nth-child(2n + 4) {\n        margin-top: 8px;\n    }\n\n    @media screen and (max-width: 600px) {\n        > * {\n            flex: 1 1 100%;\n            margin-top: 8px;\n        }\n    }\n"])));
var gridListSingleCol = css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    > * {\n        flex: 1 1 100%;\n        max-width: 100%;\n    }\n\n    > * + * {\n        margin-top: 8px;\n    }\n"])));
var gridItem = css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral([""])));
var gridTitle = css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n    display: block;\n    font-weight: bold;\n"])));
var gridValue = css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    display: block;\n"])));
var instanceDomainContent = css(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral([""])));
var instanceDomainItemList = css(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    margin-left: 1rem;\n    margin-bottom: 1rem;\n"])));
var radioBtnGroupContainer = css(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n    width: 100%;\n"])));
export { detailPageContainer, detailPageItem, detailPageHeader, detailPageTitle, detailPageEntityType, detailPageDescription, detailPageActions, detailPageProperties, detailPageBtn, gridList, gridListSingleCol, gridItem, gridTitle, gridValue, radioBtnGroupContainer, instanceDomainContent, instanceDomainItemList };